<template>
    <div>
      <div class="top">
        <div class="avatar">
          <div class="imgbox">
            <router-link :to="{'path':'/addhead'}"><img src="@/assets/img/nohead.png" alt="" /></router-link>
            <img class="zhenshi" src="../assets/img/hadeimg.png" alt="">
          </div>
          <div class="avatar-font">
            <p style="color: white; font-size: 1.3rem;font-weight: bold;">李丽丽老师</p>
            <p style="color: #ffffffba; margin-top: -0.7rem;font-size: .9rem;">13590046356</p>
          </div>
          <div>
            <img class="top-img" src="../assets/img/fengcai.png" alt="">
          </div>
        </div>
  
        <datepicker></datepicker>
        <!-- <div class="time-modul"></div> -->
      </div>
  
      <div class="card">
  
        <p class="title">考勤情况</p>
        <div class="card-1" @click="Godetail">
            <p class="card-p"><img src="../assets/img/orange.png">&nbsp;小一班</p>
            <div class="col">班主任：李丽丽</div>
          <van-row style="text-align:center;color:#999999;font-size: .8rem;">
            <van-col span="6">人数(人)</van-col>
            <van-col span="6">已到(人)</van-col>
            <van-col span="6">迟到(人)</van-col>
            <van-col span="6">未到(人)</van-col>
          </van-row>
          <van-row style="text-align:center;font-size: 1.2rem;padding-top: .8rem;" >
            <van-col span="6">32</van-col>
            <van-col span="6">29</van-col>
            <van-col span="6">2</van-col>
            <van-col span="6">1</van-col>
          </van-row>
        </div>
        
<!--         <div class="card-1"  @click="Godetail">
          <p class="card-p"><img src="../assets/img/yello.png">&nbsp;中一班</p>
          <div class="col">班主任：李丽丽</div>
          <van-row style="text-align:center;color:#999999;font-size: .8rem;">
            <van-col span="6">人数(人)</van-col>
            <van-col span="6">已到(人)</van-col>
            <van-col span="6">迟到(人)</van-col>
            <van-col span="6">未到(人)</van-col>
          </van-row>
          <van-row style="text-align:center;font-size: 1.2rem;padding-top: .8rem;" >
            <van-col span="6">50</van-col>
            <van-col span="6">45</van-col>
            <van-col span="6">2</van-col>
            <van-col span="6">3</van-col>
          </van-row>
        </div>
  
        <div class="card-1"  @click="Godetail">
          <p class="card-p"><img src="../assets/img/blue.png">&nbsp;大一班</p>
          <div class="col">班主任：李丽丽</div>
          <van-row style="text-align:center;color:#999999;font-size: .8rem;">
            <van-col span="6">人数(人)</van-col>
            <van-col span="6">已到(人)</van-col>
            <van-col span="6">迟到(人)</van-col>
            <van-col span="6">未到(人)</van-col>
          </van-row>
          <van-row style="text-align:center;font-size: 1.2rem;padding-top: .8rem;" >
            <van-col span="6">47</van-col>
            <van-col span="6">47</van-col>
            <van-col span="6">1</van-col>
            <van-col span="6">0</van-col>
          </van-row>
        </div> -->
      </div>
      
      <Foot></Foot>
    </div>
  </template>
  <script>
  import Datepicker from '../components/Datepicker.vue';
  import Foot from '../components/Foot.vue';
  export default {
    components: { Foot, Datepicker },
  
    data() {
      return {
  
      };
    },
    methods: {
      Godetail(){
        this.$router.push('/detail')
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
  //
  .top-img{
    width: 5rem;
    position: absolute;
    right: 1rem;
    top: 4rem;
  }
  /* 蓝色背景图 */
  .top {
    background: url("../assets/img/Group 427319265.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; // 等比例拉伸
    widows: 100%;
    height: 13rem;
  }
  /* 头像框 */
  .avatar {
    .imgbox {
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 3rem;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .avatar-font {
      margin-left: 0.7rem;
      margin-top: -.4rem;
    }
    display: flex;
    padding: 2rem 0 0 1rem;
  }
  .zhenshi{
    position: relative;
    top: -1.6rem;
    left: .3rem;
    width: 5rem;
  }
  /* 时间选择卡 */
  .time-modul {
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 94%;
    height: 8rem;
    margin-left: 50%;
    transform: translate(-50%);
    margin-top: 5%;
  }
  //卡片标题
  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  //卡片展示
  .card {
    margin-top: 7rem;
    width: 90%;
    margin-left: 50%;
    transform: translate(-50%);
    .card-1 {
      border-radius: 10px;
      height: 8rem;
      box-shadow: 0 1px 5px rgb(228, 227, 227);
    }
  }
  .card-p {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 1rem 0rem 0rem 1rem;
    display: flex;
  
    img{
      width: 4%;
      height: 4%;
      margin-top: .2rem;
    }
  }
  .col{
      text-align: center;
      background-color: #E4E4E4;
      padding:.1rem;
      width: 27%;
      border-top-right-radius:10px;
      border-bottom-left-radius:10px;
      font-size: .7rem;
      padding: .3rem 0 .3rem 0;
      color: #4e4f4e;
      float: right;
      margin-top: -15%;
  }
  </style>
  